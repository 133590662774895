import React, {useState, useEffect} from 'react'
import {graphql} from 'gatsby';
import Default from 'gatsby-theme-carbon/src/templates/Default';


export default function Glossary({pageContext, location, data}) {
    let page_data = data.allNodePage && data.allNodePage.edges  && data.allNodePage.edges.length > 0 ? data.allNodePage.edges[0].node : null
    if(!page_data) return <></>;
	pageContext = {...pageContext, frontmatter:
		{
			title: page_data.title
		}
	};
	return <Default pageContext={pageContext} location={location}>
        <div className="richtext-body" dangerouslySetInnerHTML={{__html: page_data.body.processed}}></div>
	</Default> ;
}

export const query = graphql`
{
    allNodePage(filter: {title: {regex: "/Independent/g"}}) {
        edges {
            node {
                title
                body {
                    processed
                }
            }
        }
    }
}
`